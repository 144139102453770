
@import "ember-bootstrap/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Martel:900|Yesteryear');

body {
	background-color: #1a5f9b;
	background-image: url("images/bg-water-sm.jpg");
	background-attachment: fixed;
	background-position: top center;
	margin-bottom: 90px;
}

.bg-white {
	background-color: #FFFFFF;
}

.site-container {
	margin-top:66px;
}

.label-fluid,
.btn-fluid {
	display:block;
	width:100%;
}

.ctr-dropped {
	margin-top: 15px;
}

.navbar-nav .btn {
	margin: 8px 15px;
}

.navbar {
	border-radius: 0px;
}

.credits {
	color: #999999;
	margin: 15px 0px;
}

.container-icons {
	margin-top:60px;

	.img-thumbnail {
		border: 2px solid #1a5f9b;
		fill: #1a5f9b;
	}

	.label {
		font-weight: normal;
		a {
			color: #FFFFFF;
		}
	}
}

.lead {
	@extend .jumbotron;
	@extend .text-center;

	background: transparent;

	h1 {
		font-family: 'Martel', cursive;
		font-weight: bold;
		-webkit-text-fill-color: white; /* Will override color (regardless of order) */
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: #1a5f9b;
	}

	h2 {
		margin-top: 0px;
		font-size: 2em;
		font-family: 'Yesteryear', cursive;
		color:#1a5f9b;
	}

}

.header-container {
	margin-bottom: 30px;
}